import React from "react"
import Seo from "../components/seo"
import BlurredSection from "../components/BlurredSection"
// import InsiderSection from "../components/our_story/insider"
import NewsHero from "../components/news/NewsHero"
import NewsSection from "../components/news/NewsSection"
import FeaturedNews from "../components/news/FeaturedNews"

const News = () => {
  return (
    <>
      <Seo title="Press & Reports" />
      <BlurredSection>
        <NewsHero />
        <FeaturedNews />
        {/* <InsiderSection /> */}
        <NewsSection />
      </BlurredSection>
    </>
  )
}

export default News
