import React from "react"
import Title from "../Title"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Col from "../Col"
import { BREAKPOINTS } from "../../utils/styled_config"

const NewsEntry = ({ title, publish_date, excerpt, coverImg, onClick }) => {
  return (
    <NewsEntryStyled onClick={onClick} as="button">
      <GatsbyImage image={getImage(coverImg)} alt="cover" quality={100} />
      <PublishDate>{publish_date}</PublishDate>
      <Title size={3} mb={3} mt={3}>
        {title}
      </Title>
      <Excerpt>
        <div dangerouslySetInnerHTML={{ __html: excerpt }}></div>
      </Excerpt>
    </NewsEntryStyled>
  )
}

const PublishDate = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 100;
  margin-bottom: 0;
  margin-top: 35px;
`

const NewsEntryStyled = styled.div`
  position: relative;
  margin-bottom: 3rem;
  cursor: pointer;
  border: 0;
  padding: 0;
  text-align: left;
  background: none;
  color: #fff;
  width: 100%;

  &:focus {
    outline: none;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    margin-bottom: 3.5rem;

    ${Col}:nth-child(even) >  & {
      padding-top: 64px;
    }
  }
`

const Excerpt = styled.div`
  font-size: 0.875rem;
`

export default NewsEntry
