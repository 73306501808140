import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Col from "../Col"
import Container from "../Container"
import Row from "../Row"
import Section from "../Section"
import NewsEntry from "./NewsEntry"
import Title from "../Title"
import NewsModal from "./NewsModal"

const NewsSection = () => {
  const [newsDetails, setNewsDetails] = useState()
  const [selectedNews, setSelectedNews] = useState()
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
  }

  const handleNewsClick = id => {
    setShow(true)
    setNewsDetails(id)
  }

  const news = useStaticQuery(graphql`
    query AllNewsQuery {
      allNewsJson {
        edges {
          node {
            id
            excerpt
            description
            title
            publish_date
            source
            video
            cover_img {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setSelectedNews(news.allNewsJson.edges.find(i => i.node.id === newsDetails))
  }, [newsDetails, news.allNewsJson.edges, selectedNews])

  return (
    <Section>
      <Container>
        <Title size={2} lg={"d2"} mbLg={4}>
          Press Release
        </Title>
        <Row className="col--news">
          {news &&
            news.allNewsJson.edges.map(n => (
              <Col lg={6} key={n.node.id}>
                <NewsEntry
                  title={n.node.title}
                  coverImg={n.node.cover_img}
                  publish_date={n.node.publish_date}
                  excerpt={n.node.excerpt}
                  onClick={() => {
                    handleNewsClick(n.node.id)
                  }}
                />
              </Col>
            ))}
        </Row>

        {selectedNews && (
          <NewsModal
            show={show}
            handleClose={handleClose}
            title={selectedNews.node.title}
            description={selectedNews.node.description}
            source={selectedNews.node.source}
            video={selectedNews.node.video}
            coverImg={selectedNews.node.cover_img}
          ></NewsModal>
        )}
      </Container>
    </Section>
  )
};

export default NewsSection
