import React from "react"
import ModalBootstrap from "../ModalBootstrap"
import Title from "../Title"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Col from "../Col"
import Row from "../Row"
import ScaledImage from "../ScaledImage"

import LineVector from "../LineVector"
import ArrowCta from "../ArrowCta"

const NewsModal = ({
  show,
  handleClose,
  title,
  coverImg,
  video,
  description,
  publish_date,
  source,
}) => {
  return (
    <ModalBootstrap show={show} handleClose={handleClose}>
      {video.length > 0 ? (
        <ScaledImage width={16} height={9}>
          <iframe
            width="100%"
            src={video}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </ScaledImage>
      ) : (
        <GatsbyImage image={getImage(coverImg)} alt="cover" />
      )}

      <br />
      <Row mb={6}>
        <Col lg={12}>
          {publish_date}
          <Title size={2}>{title}</Title>
          <LineVector height={5} width={154} />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </Col>
      </Row>
      {source.length > 0 && (
        <ArrowCta target={source} title="Read the Full Article" />
      )}
    </ModalBootstrap>
  )
}
export default NewsModal
