import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Section from "../Section"
import Container from "../Container"
import Title from "../Title"
import FeaturedNewsEntry from "./FeaturedNewsEntry"
import styled from "styled-components"
import { BREAKPOINTS } from "../../utils/styled_config"

import ChevronLeftIcon from "../Icons/ChevronLeftIcon"
import ChevronRightIcon from "../Icons/ChevronRightIcon"
import InsiderBg from "../../images/7107_insider.png"
import Carousel from "../Carousel"

const FeaturedNews = () => {
  const PrevArrow = ({ onClick, className }) => {
    return (
      <ArrowButton onClick={onClick} className={className}>
        <ChevronLeftIcon />
      </ArrowButton>
    )
  }

  const NextArrow = ({ onClick, className }) => {
    return (
      <ArrowButton onClick={onClick} className={className}>
        <ChevronRightIcon />
      </ArrowButton>
    )
  }
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const featuredNews = useStaticQuery(graphql`
    query FeaturedNewsQuery {
      allNewsJson(filter: { featured: { eq: true } }) {
        edges {
          node {
            id
            excerpt
            description
            title
            source
            publish_date
            cover_img {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Section>
      <Container>
        <Title size={2} lg={"d2"} mbLg={4}>
          Featured Articles
        </Title>
        <Grid>
          <Slider>
            <Carousel settings={settings}>
              {featuredNews &&
                featuredNews.allNewsJson.edges.slice(0, 5).map((n, idx) => (
                  <div className="embla__slide" key={idx}>
                    <FeaturedNewsEntry
                      title={n.node.title}
                      excerpt={n.node.excerpt}
                      coverImg={n.node.cover_img}
                      source={n.node.source}
                      publishDate={n.node.publish_date}
                    />
                  </div>
                ))}
            </Carousel>
          </Slider>
          <FeaturedNewsEntry
            excerpt="Know more about the Philippine market and trends with 7107 Insider, a highly informative investment resource that deep dives into the latest insights and developments in the country's hotel, leisure and tourism sectors."
            title="7107 Insider"
            staticImg={InsiderBg}
            source="/insider"
          />
        </Grid>
      </Container>
    </Section>
  )
}

const Slider = styled.div`
  position: relative;
  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    width: 49%;
    margin-right: 60px;
  }
  + div {
    @media screen and (min-width: ${BREAKPOINTS.lg}) {
      flex: 1;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}) {
      margin-top: 40px;
    }
  }
  .slick-dots {
    @media screen and (min-width: ${BREAKPOINTS.lg}) {
      position: absolute !important;
      right: 0;
      transform: translateX(52%);
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
  }
`

const ArrowButton = styled.button`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  z-index: 2;
  background: none;

  svg {
    font-size: 20px;
  }

  &.slick-prev {
    left: -60px;
  }

  &.slick-next {
    right: -60px;
  }

  &.slick-disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    height: 48px;
    width: 48px;
    display: flex;

    svg {
      font-size: 28px;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.lg}) {
    display: none;
  }
`

const Grid = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    position: relative;
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
`

export default FeaturedNews
