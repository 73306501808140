import React from "react"
import Title from "../Title"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Row from "../Row"
import Col from "../Col"
import ArrowCta from "../ArrowCta"

const FeaturedNewsEntry = ({ title, excerpt, coverImg, source, staticImg, publishDate }) => {
  return (
    <NewsEntryStyled>
      <Row>
        <Col lg={12}>
          {staticImg ? <img src={staticImg} alt="" /> : <GatsbyImage image={getImage(coverImg)} alt="cover" /> }
          <PublishDate>
            {publishDate}
          </PublishDate>
          <Title size={3} mb={3} mt={3}>
            {title}
          </Title>
          <Excerpt>{excerpt}</Excerpt>
          {source.length > 0 && (
            <ArrowCta target={source} title="Read the Full Report" />
          )}
        </Col>
      </Row>
    </NewsEntryStyled>
  )
}

const PublishDate = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 100;
  margin-bottom: 0;
  margin-top: 35px;
`;

const NewsEntryStyled = styled.div`
  position: relative;
`

const Excerpt = styled.div`
  font-size: 0.875rem;
  line-height: 1.8;
`


export default FeaturedNewsEntry
